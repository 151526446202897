<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="产品名称">
              <el-input
                v-model="searchFrom.organizationName"
                :clearable="true"
                placeholder="请输入产品名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品类别">
              <el-select :clearable="true" v-model="searchFrom.organizationCode" placeholder="请选择产品类型">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="item in productList" :key="item.dictionaryId" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间:">
              <el-date-picker :clearable="true" :picker-options="pickerOptionsStart" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="searchFrom.date1" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker :clearable="true" :picker-options="pickerOptionsEnd" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="searchFrom.date2" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label-width="68px" label="状态">
              <el-select v-model="searchFrom.state" placeholder="请选择产品状态">
                <el-option label="全部 " value=""></el-option>
                <el-option label="已上架" value="1"></el-option>
                <el-option label="已下架" value="-1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" :disabled="disabledSearch" @click="getAllProductCost(1)">查询</el-button>
              <el-button type="primary" plain icon="el-icon-refresh" @click="updateCost">重置</el-button>
            </el-form-item>
          </el-form>
          
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button type="primary" plain icon="el-icon-plus" @click="addProduct">添加</el-button>
        <el-button type="danger" plain icon="el-icon-delete" @click="handleDelete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
        <el-table :data="tableData" ref="multipleTable" @row-click="handleClickTableRow" v-loading="loading" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" fixed width="55"> </el-table-column>
          <el-table-column prop="productName" label="产品名称"> </el-table-column>
          <el-table-column prop="catalogName" label="产品类型"> </el-table-column>
          <el-table-column prop="groupName" label="团体类型"> </el-table-column>
          <el-table-column prop="salePrice1" label="销售价格"> </el-table-column>
          <el-table-column prop="handsel1" label="定金"> </el-table-column>
          <el-table-column prop="state" width="60" label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#5479FF"
                inactive-color="#D5D5D5"
                active-value="1"
                @change="changeStatus(scope.row)"
                inactive-value="0">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="startDate" width="120" label="添加时间" > </el-table-column>
          <el-table-column prop="integral" width="120" label="积分"></el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row, true)" type="text" size="small">查看</el-button>
              <el-button @click="handleClick(scope.row, false)" type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
          
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background>
          </el-pagination>
        <addNewProduct :info="info" :onlyLook="onlyLook" v-if="showaddNewProduct" @closeAddNewProduct="closeAddNewProduct"></addNewProduct>
      </div>
    </el-card>
  </div>
</template>

<script>
import { postRequest } from 'src/api/index'
import { formatTime } from 'src/utils/utils'
import addNewProduct from '@/views/productManagement/component/addNewProduct'
export default {
  data() {
    return {
      searchFrom: {
        organizationName: '',
        organizationCode: '',
      },
      tableData: [],
      multipleSelection: [],
      productList: [],
      showaddNewProduct: false,
      onlyLook: '',
      info: {},
      disabledSearch: false,
      pageNo: 1,
      pageSize: 10,
      total: null,
      loading: true,
      pickerOptionsStart: {
                     disabledDate: time => {
                            if (this.searchFrom.date2) {
                                 return time.getTime() > new Date(this.searchFrom.date2).getTime()
                           }
                    }
             },
             pickerOptionsEnd: {
                      disabledDate: time => {
                            if (this.searchFrom.date1) {
                                    return time.getTime() < new Date(this.searchFrom.date1).getTime() - 86400000
                            }
                       }
             },
    }
  },
  components: { addNewProduct },
  mounted(){
    this.getAllProductCost()
    postRequest('/dict/getDictByCategory',{dictionaryCategoryCode: '04'}).then(res=>{
      console.log(res, '会籍、单品')
      this.productList = res
    })
  },
  methods: {
    closeAddNewProduct() {
      this.showaddNewProduct = false
      this.getAllProductCost()
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val
    },
    addProduct() {
      this.info = ''
      this.onlyLook = false
      this.showaddNewProduct = true
    },
    changeStatus(val){
      console.log(val)
      let data = {
        id: val.id,
        productId: val.productId,
        groupCode: val.groupCode,
        status: val.status
      }
      postRequest('/productPirce/update', data).then(res=>{
        console.log(res, '修改结果')
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        // this.getAllProductCost()
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAllProductCost()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAllProductCost()
    },
    updateCost(){
      this.searchFrom.organizationName = ''
      this.searchFrom.organizationCode = ''
      this.searchFrom.date1 = ''
      this.searchFrom.date2 = ''
      this.searchFrom.state = ''
    },
    handleClick(val, onlyLook){
      console.log(val)
      this.info = val
      this.onlyLook = onlyLook
      this.showaddNewProduct = true
    },
    // 查询所有产品价格
    getAllProductCost (no) {
      this.loading = true
      this.disabledSearch = true
      let data = {
        productName: this.searchFrom.organizationName ? this.searchFrom.organizationName : '',
        catalogCode: this.searchFrom.organizationCode ? this.searchFrom.organizationCode : '',
        startDate: this.searchFrom.date1 ? this.searchFrom.date1 + ' 00:00:00' : '',
        endDate: this.searchFrom.date2 ? this.searchFrom.date2 + ' 23:59:59' : '',
        onsale: this.searchFrom.state ? this.searchFrom.state : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequest('/productPirce/queryProductPricePge/', data).then(res=>{
        this.disabledSearch = false
        console.log(res, '产品价格')
        this.tableData = res.data
        this.total= res.count
        this.loading = false
        res.data.map(item => {
          item.startDate = item.startDate.substring(0, 11)
          if (item.salePrice) {
            let reg = /(\d{1,3})(?=(\d{3})+(\.\d*)?$)/g
            item.salePrice1 = item.salePrice
            item.handsel1 = item.handsel
            item.salePrice1 = '¥ ' + item.salePrice1.toString().replace(reg,'$&,')
            item.handsel1 = '¥ ' +  item.handsel1.toString().replace(reg,'$&,')
          }
        })
      }).catch(err=>{
        this.loading = false
        this.disabledSearch = false
      })
    },
    // 删除方法
    handleDelete(){
      this.multipleSelection.map(item=>{
        item.startDate = item.startDate + "00:00:00"
      })
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择删除项',
          type: 'danger'
        })
      }
       this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        // this.multipleSelection.map(item => {
        //   let data = {
        //     id: item.id
        //   }
        //   postRequest('/productPirce/delete/', data).then(res=>{
        //     console.log(res),
        //     this.$message({
        //       message: '删除成功',
        //       type: 'success'
        //     })
        //     this.getAllProductCost()
        //   })
        // })
        postRequest('/productPirce/delete/', this.multipleSelection).then(res=>{
            console.log(res),
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getAllProductCost()
          })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
      
    }
  },
}
</script>

<style>
.organ-management-page {
  background: transparent;
}
/* .el-form-item--small.el-form-item {
  margin-right: 3px !important;
} */
.el-button--info {
  color: #9A9A9A;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
