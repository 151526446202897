<template>
  <el-dialog :show-close="false" :close-on-click-modal="false" :visible.sync="dialogVisible" width="50%" @close="handleClose">
    <el-card>
      <p class="title">{{title}}</p>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品类型:" prop="catalogCode">
              <el-select @change="changeSelect" :disabled="onlyLook" v-model="form.catalogCode" placeholder="请选择产品类型">
                <el-option v-for="item in productList" :key="item.dictionaryId" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="团体类别:" prop="groupCode">
              <el-select @change="changeGroup" :disabled="onlyLook" clearable v-model="form.groupCode" placeholder="请选择团队类型">
                <el-option label="-- 非会员 --" value=""></el-option>
                <el-option v-for="item in rightsType" :key="item.dictionaryId" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称:" prop="productId">
              <el-select @change="changeProductName" :disabled="onlyLook" v-model="form.productId" placeholder="请选择产品名称">
                <el-option v-for="item in productNameList" :key="item.dictionaryId" :label="item.productName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="销售价格:" prop="salePrice">
              <el-input placeholder="请输入产品销售价格" :disabled="onlyLook || form.catalogCode == 'TJ'" type="number" @mousewheel.native.prevent @keyup.native="prevent($event)" min="0" v-model="form.salePrice"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="赠送积分:" prop="integral">
              <el-input placeholder="请输入积分" :disabled="onlyLook" type="number" @mousewheel.native.prevent @keyup.native="prevent($event)" v-model="form.integral"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="定金:" prop="handsel" :rules="form.catalogCode == 'TJ'?[{ required: true, message: '请输入定金'}]:[]">
              <el-input :disabled="onlyLook" @change="numberChange(arguments[0],form.salePrice)" @input="numberChange(arguments[0],form.salePrice)" type="number" @mousewheel.native.prevent @keyup.native="prevent($event)"
                @blur="inputHandsel" v-model="form.handsel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="起止时间:">
              <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" @input="changeDate" v-model="form.value1" :disabled="onlyLook" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="开始时间" prop="startDate">
              <el-date-picker
                @change="changeStartDate"
                :disabled="onlyLook"
                v-model="form.startDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间">
              <el-date-picker
                v-model="form.endDate"
                :disabled="onlyLook"
                value-format="yyyy-MM-dd"
                type="date"
                :picker-options="expireTimeOption"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品上下架:" prop="onsale">
              <el-select :disabled="onlyLook" v-model="form.onsale">
                <el-option label="上架" value="1"></el-option>
                <el-option label="下架" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommite('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequest } from 'src/api/index'
import { isDecimal } from 'src/utils/vaildate'
var validDecimal = (rule, value, callback) => {
  if (!value) {
    callback()
  } else if (!isDecimal(value)) {
    callback(new Error('请输入0-1之间的数'))
  }
}
export default {
  data() {
    return {
      dialogVisible: true,
      productList: [],
      rightsType: [],
      productNameList: [],
      title: '添加产品价格',
      isHJ: true,
      isDisabled: false,
      form: {
        catalogCode: '',
        productId: '',
        salePrice: '',
        handsel: '0.00',
        integral: '',
        productInfo: '',
        groupCode: '',
        singlePrice: 1,
        value1: '',
        startDate: '',
        endDate: '',
      },
      rules: {
        singlePrice: [{ required: false, trigger: 'blur', validator: validDecimal }],
        catalogCode: [{ required: true, trigger: 'change', message: '请选择产品类型' }],
        groupCode: [{ required: false, trigger: 'change', message: '请选择团体类别' }],
        productId: [{ required: true, message: '请选择产品名称' }],
        salePrice: [{ required: true, trigger: 'blur', message: '请输入产品价格' }],
        // handsel: [{ required: false, trigger: 'blur', message: '请输入定金金额' }],
        integral: [{ required: true, trigger: 'blur', message: '请输入积分' }],
        startDate: [{ required: true, trigger: 'change', message: '请选择开始时间' }],
        onsale: [{ required: true, trigger: 'change', message: '请选择上下架状态' }],
      },
      expireTimeOption: {
        disabledDate: date => {
          return date.getTime() < new Date(this.form.startDate)
        },
      },
    }
  },
  props: ['info', 'onlyLook'],
  computed: {
    isChange() {
      return this.onlyLook || this.isDisabled
    },
  },
  mounted() {
    // 产品类型获取
    if (this.info) {
      this.isDisabled = true
      this.info.value1 = [this.info.startDate + '00:00:00', this.info.endDate]
      this.form.groupCode = Number(this.info.groupCode)
      this.form = this.info
      if (this.onlyLook) {
        this.title = '查看'
      } else {
        this.title = '修改产品价格'
      }
      // postRequest('/product/queryProduct/', {id: this.info.productId}).then(res=>{
      //   console.log(res, '产品介绍查询结果')
      //   if (this.info == '') {
      //     this.form.introduction = res[0].introduction
      //   }
      // })
    }
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '04' }).then(res => {
      console.log(res, '会籍、单品')
      this.productList = res
    })
    // 团体类别获取
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '06' }).then(res => {
      console.log(res, '家庭、精英、SVIP')
      this.rightsType = res
    })
    postRequest('/product/queryProduct/', { onsaleStatus: 1 }).then(res => {
      console.log(res, 'res,111')
      this.productNameList = res
    })
  },
  methods: {
    handleClose() {
      this.$emit('closeAddNewProduct')
    },
    changeStartDate() {
      this.$forceUpdate()
      this.form.endDate = ''
    },
    changeDate(val) {
      console.log(val)
      this.$forceUpdate()
      ;(this.form.startDate = val[0]), (this.form.endDate = val[1])
    },
    numberChange(val, maxNum) {
      this.$forceUpdate()
      //转换数字类型
      this.form.handsel = Number(val)
      if (this.form.catalogCode == 'TJ') {
        this.form.salePrice = this.form.handsel
        return
      }
      //重新渲染
      this.$nextTick(() => {
        //比较输入的值和最大值，返回小的
        let num = Math.min(Number(val), maxNum)
        //输入负值的情况下， = 0（可根据实际需求更该）
        if (num < 0) {
          this.form.handsel = 0
        } else {
          //反之
          this.form.handsel = num
        }
      })
    },
    inputHandsel() {
      console.log(this.form.handsel == '')
      if (this.form.handsel == '') {
        this.form.handsel = 0
      }
    },
    // 根据产品类型查询 产品名称
    getNameByType(data) {
      postRequest('/product/queryProduct/', data).then(res => {
        console.log(res, '产品名称')
        this.productNameList = res
      })
    },
    prevent(e) {
      var keynum = window.event ? e.keyCode : e.which //获取键盘码
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning('禁止输入小数以及负数')
        e.target.value = ''
      }
    },
    changeSelect(val) {
      if (val == 'HJ') {
        this.isHJ = false
      } else {
        this.isHJ = true
      }
      this.form.productId = null
      let data = {
        catalogCode: val,
      }
      this.getNameByType(data)
      this.productList.map(item => {
        if (item.dictionaryCode == val) {
          this.form.catalogName = item.dictionaryName
        }
      })
    },
    changeGroup(val) {
      this.rightsType.map(item => {
        if (item.dictionaryCode == val) {
          this.form.groupName = item.dictionaryName
        }
      })
    },
    changeProductName(val) {
      this.form.money = ''
      this.$forceUpdate()
      this.productNameList.map(item => {
        if (item.id == val) {
          this.form.productName = item.productName
        }
      })
    },
    sureCommite(form) {
      if (this.onlyLook) {
        this.handleClose()
      } else {
        if (this.form.singlePrice == '') {
          this.form.singlePrice = 1
        }
        if (
          this.form.catalogCode == '' ||
          this.form.productId == '' ||
          this.form.salePrice == '' ||
          this.form.integral == ''
        ) {
          this.$message({
            message: '请完善信息',
            type: 'warning',
          })
        } else {
          if (this.info != '') {
            this.form.startDate = this.form.startDate + '00:00:00'
            postRequest('/productPirce/update/', this.form).then(res => {
              console.log(res)
              this.$message({
                message: '修改成功',
                type: 'success',
              })
              this.handleClose()
            })
          } else if (this.info == '') {
            postRequest('/productPirce/addSave/', this.form).then(res => {
              console.log(res)
              this.$message({
                message: '添加成功',
                type: 'success',
              })
              this.handleClose()
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
}
.el-date-editor {
  width: 100%;
}
.title {
  color: #444444;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: PingFang SC;
  font-weight: 400;
}
.el-card {
  margin-top: -25px;
}
</style>
